form {
  padding: 70px 0 30px 0;
  input,
  textarea {
    width: 100%;
    background-color: transparent;
    border: 0px;
    outline: none;
    box-shadow: none;
    border-bottom: 1px solid #707070;
    height: 30px;
    margin-bottom: 30px;
  }
  textarea {
    min-height: 100px;
  }
  @media (max-width: 860px) {
    padding: 30px 0;
  }
}
button {
  border: 1px solid #7620ff;
  background-color: #7620ff;
  width: 100%;
  outline: none;
  color: #fff;
  :hover {
    background-color: #580cd2;
    border: 1px solid #7620ff;
    color: #fff;
  }
  @media (max-width: 991px) {
    margin: 0 auto;
  }
}

.success {
  color: green;
  font-size: 20px;
  text-align: center;
}
.error {
  color: red;
  font-size: 20px;
  text-align: center;
}
#contactImg {
  @media (max-width: 992px) {
    display: none;
  }
}
